
<template>
  <div class="box-started">
    <div id="home-page" class="container">
      <div class="group-word text-center">
        <h1 class="oops">Oops!</h1>
        <h1 class="key-word">404 - ไม่พบหน้าดังกล่าว</h1>
        <p>หน้านี้อาจถูกลบ หรือ ถูกย้ายไปแล้ว หรือ ปรับปรุงชั่วคราว</p>
        <br />
        <p>The page you are looking for might have been removed had its</p>
        <p>name changed or is temporarily unavailable.</p>
        <a href="/" class="btn-back">กลับหน้าหลัก</a>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scope>
.group-word {
  margin-bottom: 30px;
  .key-word {
    font-size: 24px;
    span {
      font-weight: 500;
    }
  }
  .oops {
    font-family: Kanit, sans-serif;
    font-size: 110px;
    margin: 0;
    font-weight: 900;
    background: url(../assets/bgText.png) no-repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: cover;
    background-position: 50%;
  }
  .btn-back {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    cursor: pointer;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 16px;
    background-color: #0f4c82;
    color: #fff;
    border-radius: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
</style>
<script>
export default {
  data() {
    return {};
  },
  mounted() {
    this.backToHome()
  },
  methods: {
    backToHome() {
      setTimeout(() => {
        window.location.assign("/");
      }, 1500);
    },
  },
};
</script>